import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ContactForm from "../components/ContactForm"
import PostPreview from "../components/PostPreview"
import SubPageHeader from "../components/SubPageHeader"
import {graphql} from 'gatsby'

class IndexPage extends React.Component {
    render() {
        const items = [];
        const posts = this.props.data.allMarkdownRemark.edges;
        posts.forEach((post) => {
            items.push(<PostPreview data={post} key={post.node.frontmatter.path}/>);
        });

        return (
            <Layout>
                <SEO title="Klarer sehen mit Storymapping" description="Um alle Schritte bis zu einem Ziel deutlich zu visualisieren, benötigt man eine Storymap. Sie vermittelt dem Team ein gemeinsames Verständnis für alle Inhalte des Projekts."/>
                <div className="SubPage-wrapper">
                    <SubPageHeader
                        subPageTitle="Klarer sehen mit Storymapping"
                        subPageDescription={<React.Fragment>
                            <p>
                                “The best solutions come from collaboration between the people with the problems to solve and the people who can solve them.”
                                „Die besten Lösungen entstehen aus der Zusammenarbeit zwischen Menschen, die Probleme haben, und Menschen, die sie lösen können. ”
                                – Jeff Patton, User Story Mapping: Discover the Whole Story, Build the Right Product
                            </p>
                            <p>
                                Eine User Story Map visualisiert im Idealfall alle Schritte, die eine Person gehen muss, um ein bestimmtes Ziel zu erreichen. Diesen Schritten werden die dafür notwendigen Aufgaben zugeordnet. Dabei werden meist auch deren Zusammenhänge transparent. Stellt Euch das User Story Mapping wie das Zeichnen einer Landkarte vor, die Eure Anwender von einem Punkt zum nächsten führt, sodass sie schließlich ihr Ziel erreichen.
                                Das in der Zusammenarbeit aller beteiligten Personen entstehende Bild vermittelt dem Team ein gemeinsames Verständnis für alle Inhalte des Projekts.
                            </p>
                        </React.Fragment>}
                    />
                    <div className="IndexPosts-container">
                        {items}
                    </div>
                </div>
                <ContactForm/>
            </Layout>
        );
    }
}

export default IndexPage;

export const pageQuery = graphql`
query StorymapQuery {
  allMarkdownRemark(filter: {frontmatter: {category: {eq: "storymap"}}}, sort: {order: DESC, fields: frontmatter___date}) {
    edges {
      node {
        timeToRead
        frontmatter {
          description
          date
          category
          image {
            childImageSharp {
                resize(quality: 100, width: 800) {
                    src
                }
            }
          }
          path
          title
        }
      }
    }
  }
}
`;
